<template>
  <div class="menu">
    <div @click="toggleCollapsed" class="toggle-menu">
      <svg class="iconfont" aria-hidden="true">
        <use :xlink:href="'#icon-' + (state.collapsed ? 'menu22' : 'menu1')" />
      </svg>
    </div>
    <div class="logo" :class="state.collapsed ? 'small-logo' : 'large-logo'">
      <img src="../assets/images/img/artemisads-logo.svg" />
    </div>
    <div class="content">
      <a-menu
        @click="onClick"
        v-model:selectedKeys="state.activeMenu"
        mode="inline"
        :inline-collapsed="state.collapsed"
        :items="menus"
      ></a-menu>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref } from 'vue'

const props = defineProps(['menus'])

const state = ref({
  collapsed: false,
  activeMenu: ['products'], // beforeMount修改
})
const onClick = ({ item }) => {
  location.href = item.path
}
const toggleCollapsed = () => {
  state.value.collapsed = !state.value.collapsed
}
function hashChange(e) {
  setActiveMenu()
}

function setActiveMenu() {
  const hash = '/' + location.hash

  for (const menu of props.menus) {
    if (hash.indexOf(menu.path) != -1) {
      state.value.activeMenu = [menu.key]
      break
    }
  }
}
onBeforeMount(() => {
  setActiveMenu()
  window.removeEventListener('hashchange', hashChange)
})
onMounted(() => {
  window.addEventListener('hashchange', hashChange)
})
</script>

<style lang="less" scoped>
.menu {
  // box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 24px;
  background: var(--sider-bg);
  position: relative;
  border-right: 1px solid var(--border-4);
  .toggle-menu {
    position: absolute;
    z-index: 3;
    cursor: pointer;
    right: -24px;
    top: 10px;
    width: 24px;
    height: 28px;
    background: #ffffff;
    border: 1px solid var(--border-4);
    box-shadow: 0 1px 0 0 var(--box-shadow-2);
    border-radius: 0 8px 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dark-4);
    .icon {
      font-size: 14px;
    }
    &:hover {
      color: var(--dark-3);
      background-color: var(--bg-gray-1);
      border-color: var(--border-1);
    }
    &:active {
      color: var(--dark-2);
      background-color: var(--bg-gray-2);
      border-color: var(--bg-gray-7);
    }
  }
  .logo {
    width: 60px;
    height: 40px;
    overflow: hidden;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
    img {
      position: absolute;
      left: 20px;
      top: 0;
      min-width: 176px;
      width: 176px;
      height: 40px;
    }
  }
  .large-logo {
    width: 264px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  margin: 16px 8px 0;
  :deep(.ant-menu) {
    border-inline-end: none;
    background: var(--sider-bg);
    &.ant-menu-inline-collapsed {
      width: 64px !important;
    }
    .ant-menu-item {
      font-family: Sora-Regular;
      font-weight: 400;
      font-size: 15px;
      color: var(--dark-1);
      letter-spacing: 0.4px;
      line-height: 20px;
      border-radius: 50px;
      height: 40px;
      position: relative;
      border-radius: 8px !important;
      padding-left: 16px !important;
      &:hover {
        background-color: var(--bg-gray-6);
      }
      &:active {
        background-color: var(--bg-gray-4);
      }
    }
    .ant-menu-item-icon {
      font-size: 24px;
      margin-right: 6px;
      height: 40px;
    }
    .ant-menu-item-selected {
      opacity: 1;
      background-color: var(--primary-selectd);
      color: var(--primary-text);
      border-radius: 50px;
      font-weight: 500;
      &:hover {
        background-color: var(--primary-selectd);
        color: var(--primary-text);
      }
      &::after {
        position: absolute;
        top: 10px;
        left: 0;
        width: 3px;
        height: 20px;
        background-color: var(--primary-text);
        border-radius: 50px;
      }
      .ant-menu-item-icon {
        color: var(--primary-text);
        opacity: 1;
      }
    }
  }
}
</style>