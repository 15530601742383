/*
 * @Author: Gang Jiang 
 * @Date: 2024-11-27 09:28:42 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2024-12-05 14:23:41
 */
 <template>
  <div class="bottom">
    <div class="img">
      <img src="../assets/images/admin/amazon.png" alt="Artemis Ads" />
    </div>
    <div class="word">
      <div>Copyright 2024 ArtemisAds Inc.All rights reserved</div>
      <div class="txt">
        <a href="/#/privacy-policy">Privacy Policy | </a>
        <a href="/#/terms-of-service">Terms of Service</a>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  width: 100%;
  padding: 16px 32px;
  .img {
    width: 185px;
    height: 32px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .word {
    font-family: Sora-Regular;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.3px;
    text-align: right;
    line-height: 16px;
    text-align: right;
    div:first-of-type {
      color: var(--dark-3);
    }
  }
  .txt {
    > a {
      font-family: Sora-Regular;
      font-weight: 500;
      font-size: 12px;
      color: var(--dark-3);
      letter-spacing: 0.3px;
      text-align: right;
      line-height: 16px;
      text-align: right;
      &:hover {
        color: var(--dark-2);
      }
      &:active {
        opacity: var(--dark-1);
      }
    }
  }
}
</style>