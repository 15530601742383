/*
 * @Author: Gang Jiang 
 * @Date: 2024-11-25 15:26:36 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2024-12-05 14:28:40
 */
<template>
  <div class="publisher">
    <div class="left">
      <left-menu :menus="menu" />
    </div>
    <div class="right">
      <top-bar :welcome="'Welcome to ArtemisAds'" />
      <div class="content">
        <router-view></router-view>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script setup>
import { h } from 'vue'
import topBar from '../../components/topBar.vue'
import LeftMenu from '../../components/LeftMenu.vue'
import Footer from '../../components/Footer.vue'

// const menu = [
//   {
//     key: 'products',
//     label: 'Products Catelogue',
//     icon: () => h('i', { class: 'iconfont icon-shangpin' }),
//     path: '/#/publisher/products',
//   },
//   {
//     key: 'brands',
//     label: 'Brands Catelogue',
//     icon: () =>
//       h('i', { class: 'iconfont icon-pinpai' }),
//     path: '/#/publisher/brands',
//   },
//   {
//     key: 'links',
//     label: 'Product Links',
//     icon: () =>
//       h('i', { class: 'iconfont icon-lianjie' }),
//     path: '/#/publisher/product-links',
//   },
// ]
const menu = [
  {
    key: '/#/publisher/profile',
    icon: () =>
      h('svg', { class: 'icon', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-a-shezhi2' }),
      ]),

    routeName: 'profile',
    title: 'Settings',
    label: 'Settings',
    path: '/#/publisher/profile',
  },
]
</script>

<style lang="less" scoped>
.publisher {
  display: flex;
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  background: #f4f2f7;
  :deep(label) {
    font-weight: normal;
  }
  :deep(.content-wrapper) {
    display: flex;
    border-radius: 8px;
    padding: 15px 15px 0;
  }
  .right {
    flex: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;
  }
  .content {
    margin-top: 1px;
    overflow-y: auto;
  }
}
</style>